import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

console.log(process.env.TOKEN);

export const client = sanityClient({
  projectId: '8uwff5b0',
  dataset: 'production',
  apiVersion: '2024-09-01',
  useCdn: true,
  token: 'skjysj10OKpTYsUa1CgwgxIMObccv5KrnF62jNMfYXSTTzjB1DDFwrjocgle0fBTDOrIzMMPi8LYPdGHvgjKU2ynVmu8RNPB5fCihMlHtOJE0zDTBmLvgpSTiE8p7uRo9huU5gaGJ9E3SIhMvfwJm3oG0JLRJrZrkGMn1iwwg7gPrM2ymAZ3',
});

const builder = imageUrlBuilder(client);
export const urlFor = (source) => builder.image(source);
