import React from 'react';
import ReactGA from 'react-ga4';

import { About, Footer, Header, Skills, Testimonial } from './container';
import { Navbar } from './components';
import './App.scss';

ReactGA.initialize('G-59C7FV3YX4');

function App() {
  ReactGA.initialize('G-59C7FV3YX4');
  return (
    <div className="app">
      <Navbar />
      <Header />
      <About />
      {/* <Work /> */}
      <Skills />
      <Testimonial />
      <Footer />
    </div>
  );
}
// const App = () => (
//   <div className='app'>
//     <Navbar />
//     <Header />
//     <About />
//     {/* <Work /> */}
//     <Skills />
//     <Testimonial />
//     <Footer />
//   </div>

// );

export default App;
